import { useTranslation } from 'react-i18next';
import './styles.scss';
import { ReactComponent as SFCLogoColor } from '../../../../../assets/icons/sfc.svg';

interface Props {
  darkMode?: boolean;
}

export const SfcLogo = ({ darkMode }: Props) => {
  const { t } = useTranslation();
  return (
    <div className='flex gap-x-2 justify-end items-center'>
      <p className='font-body-b2-sb'>{t('logistics.sfcCertified')}</p>
      <SFCLogoColor style={{ filter: darkMode ? 'grayscale(100%)' : 'unset' }} />
    </div>
  );
};
