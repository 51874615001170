import { useTranslation } from 'react-i18next';
import { SfcLogo } from '../../../../../../measure/hubs/components/sfcLogo/SfcLogo';

type Props = {
  page: number;
  total: number;
  darkStyle?: boolean;
  isGlec?: boolean;
};

const DcycleFooter = ({ page, total, darkStyle = false, isGlec }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='report-footer'>
      {page === 1 && isGlec ? <SfcLogo darkMode={darkStyle} /> : null}
      <div className={`${darkStyle ? 'tag-white' : 'tag-black'} body3-font`}>dcycle.io</div>
      <div
        className={`${darkStyle ? 'tag-transparent-black' : 'tag-transparent-white'} body3-font`}>
        app.dcycle.io
      </div>
      <span className={`${darkStyle ? 'on-dark-text-color' : ''} body1-font`}>
        {t('ghgReport.page', { page, total })}
      </span>
    </div>
  );
};

export default DcycleFooter;
